<template>
    <v-card flat>      
        <v-card-text class="pb-0 mb-0">
            <v-row>
                <v-col style="color:#4E87DD; font-size:26px; padding-top:60px; padding-bottom:30px;" class="text-center">{{ dispMsg }}</v-col>
            </v-row>
            <v-row>
                <v-col style="color:#555555; font-size:18px; padding-bottom:100px;" class="text-center">
                    <div style="font: normal normal normal 20px/37px Apple SD Gothic Neo;">담당자 검토 후, 승인완료 시 서비스 접속이 가능합니다.</div>
                    <div style="font: normal normal normal 20px/37px Apple SD Gothic Neo;">검토 기간은 최대 3일 소요(업무일 기준)되며,</div>
                    <div style="font: normal normal normal 20px/37px Apple SD Gothic Neo;">결과는 등록하신 휴대폰 문자메시지를 통해 안내해 드립니다.</div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="text-center mt-n10">
            <v-btn color="#4E87DD" width="300px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo;" @click="doNext">홈으로 이동 &gt;&gt;</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'join_step_06',
    props: [
        'msg'
    ],
    components: {
    },
    data: () => ({
        defaultMsg: '서비스 담당자에게 가입요청 하였습니다.',
        processDoneFlag: false,
    }),
    computed: {
        ...mapGetters({
            joinStep: 'join/getJoinStep',
        }),
        dispMsg() {
            return this.msg ? this.msg : this.defaultMsg
        }
    },
    created () { 
    },
    mounted () { 
        this.$store.dispatch('join/init', {})
        this.$store.commit('join/setJoinStep', 6)
    },
    methods: {
        doNext() { 
            this.$router.push('/login')
        }
    }
}
</script>
<style>
</style>


